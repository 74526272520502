/*  B  */

button {
    padding-top: 0;
    padding-bottom: 0;
    margin: 0 10px 10px;
    cursor: pointer;
    padding: 10px;
    text-transform: uppercase;
    font-weight: bold;
    border: 3px solid white;
    background: white;
    color: darken(white, 2%);
    border-radius: 10px;
}

.bannerLogo {
    flex: 1;
    width: 50%;
    height: 70%;
}

.BodyA {
    display: flex;
    flex: 5;
    flex-direction: row;
    margin: 5px;
}

.BodyGeneral {
    flex: 4;
}

.botonesBody {
    display: flex;
    flex: 1;
    flex-direction: row;
}

.BotonesCategoria {
    background-color: #2B4F72;
    flex: 1;
    min-height: 40px;
    margin: 0%;
    font-weight: bold;
    color: white;
}

.BotonesGrupos {
    background-color: #449E5E;
    flex: 0.5;
    min-height: 40px;
    margin: 0%;
    font-weight: bold;
    color: white;
}

.BotonesEtapa {
    background-color: #2B4F72;
    flex: 1;
    min-height: 40px;
    color: white;
    margin: 0%;
    font-weight: bold;
}

/*  C  */



.CerrarSesion {
    margin: 30px;
    width: 150px;
    font-size: x-small;
}

/*  D  */

.divTitulo {
    justify-content: center;
    align-items: center;
}

.divTexto {
    flex: 1;
}

.divTexto1 {
    flex: 1;
}

/*  E  */

.espacioblanco {
    margin-bottom: 350px;
}

.espacio {
    margin-bottom: 20px;
}



/*  F  */

.Fechas {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 100px;
    height: 100px;
    margin: 15px;
    padding: 15px;
    background-color: #00A19A;
    justify-content: center;
    align-content: center;
}

.FicherosSubir {
    display: flex;
    flex-direction: row;
}

.FechasPeques {
    display: flex;
    flex-direction: column;
    width: 55px;
    height: 55px;
    background-color: #00A19A;
    margin: 5px;
    padding: 5px;
    justify-content: center;
    align-content: center;

}

.fixtureGeneral {
    margin: 0;
    display: flex;
    flex: 1;
}

.Fichero {
    display: flex;
    flex-direction: column;
    flex: 1;
    margin: 5px;
    padding: 3px;
    align-items: center;
    border: 1px solid #2B4F72;
}

.FootA {
    display: flex;
    flex: 0.6;
    flex-direction: row;
    align-self: center;
    margin: 5px;
    font-weight: bold;
}

/*  H  */
 
.HeaderA {
    display: flex;
    flex: 1;
    flex-direction: row;
    margin: 5px;
}



.HojaEquipos {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
 
.HojaEquipos1 {
    display: flex;
    flex-direction: row;
}

.HEquipo {
    display: flex;
    flex-direction: column;
    flex: 1;
}


/*  I  */



/*  L  */

.LogoHeader {
    display: flex;
    flex: 1.7;
}


/*  M  */

.MenuHeader {
    flex: 1;
    display: flex;
    flex-direction: row;
    align-self: center;
    align-items: center;
    justify-content: center;
}

.Menu1 {
    flex: 1;
    min-height: 40px;
    margin: 0;
    color: white;
    background-color: #449E5E;
    font-weight: bold;
}

.Menu2 {
    flex: 1;
    min-height: 40px;
    margin: 0%;
    color: white;
    background-color: #449E5E;
    font-weight: bold;
}

.Menu3 {
    background-color: #00A19A;
    flex: 1;
    min-height: 40px;
    margin: 0%;
    font-weight: bold;
}

.MenuOpciones {
    flex: 1;
}

.MenuCategorias {
    flex: 1;
}



/*  N  */



.nombreCampeonato {
    margin: 0;
    padding: 0;
    font-size: 24px;
    font-weight: bold;
    color: #2B4F72;
    text-align: center;
    align-self: center;
}

.nombreFecha {
    flex: 0.5;
    margin-top: 10px;
    align-self: center;
    justify-self: center;
    text-align: center;
    font-weight: bold;
    color: white;
    font-size: medium;
}

.numero {
    flex: 1;
    font-size: small;
    font-weight: bold;
    text-align: right;
    margin-left: 5px;
    margin-right: 5px;
}

.nombre {
    flex: 1;
    font-size: small;
    font-weight: bold;
    text-align: left;
    margin-left: 5px;
    margin-right: 5px;
}

.numero1 {
    flex: 1;
    font-size: small;
    text-align: right;
    margin-left: 5px;
    margin-right: 5px;
}

.nombre1 {
    flex: 1;
    font-size: small;
    text-align: left;
    margin-left: 5px;
    margin-right: 5px;
}

/*  P  */



p {
    margin: 0px;
}

/*  S  */

.sub1 {
    font-size: small;
    color: gray;
}

.Subtitulo {
    font-size: 13pt;
    font-weight: bold;
    color: #2B4F72;
}



.subtitulosHoja {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.SubtituloHoja {
    font-size: medium;
    text-align: center;
    font-weight: bold;
    padding: 5px;
    background-color: #2B4F72;
    color: white;
}

.SubtituloHoja1 {
    font-size: small;
    text-align: center;
    font-weight: bold;
    padding: 2px;
    background-color: #449E5E;
    color: white;
}

/*  T  */

.tablaPosiciones {
    display: flex;
    flex: 1;
    justify-content: center;
    flex-direction: column;
    margin: 0;
}

.TituloInformePenas {
    background-color: blue;
    color: white;
    font-weight: bold;
    font-size: large;
}



.TituloHoja {
    font-size: x-large;
    text-align: center;
    font-weight: bold;
    padding: 10px;
    color: #2B4F72;
}

.TodoA {
    display: flex;
    flex: auto;
    flex-direction: column;
    height: 100vh;
    width: 100vw;
    background-color: white;
}

.TodoMenu {
    display: flex;
    flex-direction: column;
}

.tabla {
    flex: 1;
    padding-left: 45px;
    padding-right: 45px;
    margin: 5px;
    margin-top: 150px;
    margin-bottom: 10px;
}


.tituloTabla {
    margin: 0;
    padding: 0;
    font-size: 24px;
    font-weight: bold;
    align-self: center;
    text-align: center;
}

.tituloTabla1 {
    margin-top: 18px;
    background-color: rgba(255, 255, 255, 0.548);
    justify-items: center;
}

.Titulo {
    font-size: 20pt;
    font-weight: bold;
    text-align: center;
    margin-bottom: 10px;
}

.Titulo1 {
    font-size: 20pt;
    font-weight: bold;
    text-align: center;
    margin-bottom: 10px;
    color: #2B4F72;
}

/*  U  */

ul {
    list-style: none;
}