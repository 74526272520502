.bodyEquipos {
	display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.bannerPie {
    flex: 1;
}

.BotonesDescarga {
    display: flex;
    flex: 1;
    flex-direction: column;
}

.botonesExtras {
    flex: 1;
    background-color: #2B4F72;
    color: white;
}

.cancha {
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
}

.divPenas {
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
}

.divTextoFix {
    background-color: #7E94A9;
    display: flex;
    flex: 1;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    align-content: center;
    margin-bottom: 10px;
}

.dia {
    display: flex;
    flex: 0.8;
    justify-content: center;
    align-items: center;
}

.DivBotones {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.estadio {
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
}

.Equipos {
    display: flex;
    flex-direction: column;
    width: 150px;
    height: 150px;
    background-color: rgba(255, 255, 255, 0.247);
    margin: 15px;
    padding: 15px;
}

.EscudoGrande {
    flex: 3;
    height: 15vh;
    margin: 0;
    align-self: center;
    justify-self: center;
}

.EquiposPeques {
    display: flex;
    flex-direction: column;
    width: 85px;
    height: 65px;
    background-color: rgba(255, 255, 255, 0.247);
    margin: 5px;
    padding: 5px;
}

.fixture {
    display: flex;
    flex: 1;
    flex-direction: row;
    justify-items: center;
    align-content: center;
    justify-content: center;
    align-items: center;
    margin: 0%;
    width: 900px;
}

.fixtureTitulos {
    display: flex;
    flex-direction: row;
    justify-items: center;
    align-content: center;
    justify-content: center;
    align-items: center;
    background-color: #2B4F72;
    padding: 5px;
}

.fixture1 {
    display: flex;
    flex-direction: column;
    flex: 8;
    margin-top: 20px;
    margin-bottom: 20px;
    margin-right: 20px;
    justify-items: center;
    align-content: center;
}

.fotoEscudo {
    flex: 3;
    height: 8vh;
    margin: 0;
    align-self: center;
    justify-self: center;
}

.fotoEscudo1 {
    display: flex;
    flex: 1;
    height: 10vh;
    align-self: flex-start;
    align-self: center;
    justify-self: center;
    justify-content: center;
    margin-right: 3px;
}

.fotoEscudo2 {
    display: flex;
    flex: 1;
    height: 10vh;
    align-self: flex-start;
    align-self: center;
    justify-self: center;
    justify-content: center;
    margin-left: 3px;
}

.fotoEscudoPeque {
    flex: 3;
    height: 1vh;
    margin: 0;
    align-self: center;
    justify-self: center;
}

.FechasPeques {
    display: flex;
    flex-direction: column;
    width: 55px;
    height: 55px;
    background-color: #00A19A;
    margin: 5px;
    padding: 5px;
    justify-content: center;
    align-content: center;

}

.Fechas {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 100px;
    height: 100px;
    margin: 15px;
    padding: 15px;
    background-color: #00A19A;
    justify-content: center;
    align-content: center;
}

.HojaVocalia {
    background-color: white;
}

.hora {
    display: flex;
    flex: 0.8;
    justify-content: center;
    align-items: center;
}

.InformePenas {
    width: 550px;
    height: 150px;
}

.ImagenDescarga {
    flex: 1;
    margin-top: 5px;
}

.LogoHeader {
    display: flex;
    flex: 1.7;
}

.listaEquiposA {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 120px;
    margin-bottom: 20px;
    margin-left: 30px;
    margin-right: 30px;
    justify-content: center;
    min-width: 288px;
    max-width: 900px;
}

.listaEquiposPeque {
    display: flex;
    flex-direction: row;
    flex: 1;
    margin: 20;
    max-width: 700px;
    max-height: 70px;
    align-self: center;
    overflow: auto;
    -ms-overflow-style: none;
    background-color: #BAE5E4;
    padding: 10px;
    border-radius: 10px;
}

.muestraEquipo {
    flex: 8;
    margin-top: 120px;
    margin-bottom: 20px;
    margin-right: 50px;
    margin-left: 70px;
}

.nombreEquipo {
    flex: 0.5;
    margin-top: 10px;
    align-self: center;
    justify-self: center;
    text-align: center;
    font-weight: bold;
    color: #2B4F72;
}

.nombreEquipoPeque {
    flex: 0.5;
    align-self: center;
    justify-self: center;
    text-align: center;
    font-weight: bold;
    font-size: 6pt;
    color: #2B4F72;
}

.nombreFechaPeque {
    flex: 0.5;
    margin-top: 3px;
    align-self: center;
    justify-self: center;
    text-align: center;
    font-weight: bold;
    color: white;
    font-size: x-small;
}

.nombreFecha {
    flex: 0.5;
    margin-top: 10px;
    align-self: center;
    justify-self: center;
    text-align: center;
    font-weight: bold;
    color: white;
    font-size: medium;
}

.partido {
    display: flex;
    flex: 7;
    flex-direction: column;
    margin: 4px;
    align-content: center;
    justify-content: center;
    width: 100%;
}

.partido1 {
    display: flex;
    flex: 1;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.partido2 {
    display: flex;
    flex: 6;
    flex-direction: column;
}

.partido3 {
    display: flex;
    flex: 2;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.partido4 {
    flex: 0.2;
    justify-content: center;
    align-items: center;
    margin-left: 0px;
}

.Subtitulo {
    font-size: 13pt;
    font-weight: bold;
    color: #2B4F72;
}

.SubtituloFix {
    font-size:small;
    font-weight: bold;
    text-align: center;
    color: white;
}

.SubtituloFix1 {
    font-size: large;
    font-weight: bold;
    text-align: center;
}

.TituloInformePenas {
    background-color:chocolate;
    color: white;
    font-weight: bold;
    font-size: large;
    text-align: center;
}

.Titulo {
    font-size: 20pt;
    font-weight: bold;
    text-align: center;
    margin-bottom: 10px;
}

.tituloTabla1 {
    margin-top: 18px;
    background-color: rgba(255, 255, 255, 0.548);
    justify-items: center;
}

.tituloTabla {
    margin: 0;
    padding: 0;
    font-size: 24px;
    font-weight: bold;
    align-self: center;
    text-align: center;
}

.tablaPosiciones1 {
    flex: 1;
    display: flex;
    justify-content: center;
    flex-direction: column;
    width:max-content;
}

.tablaPosiciones {
    display: flex;
    flex: 1;
    justify-content: center;
    flex-direction: column;
}

.textoFix {
    font-size: small;
    text-align: center;
    margin-left: 5px;
    margin-right: 5px;
    padding-top: 5px;
    padding-bottom: 5px;
    color: white;
}

.textoFix1 {
    display: flex;
    flex: 2;
    font-size: larger;
    text-align: center;
    margin-left: 2px;
    margin-right: 2px;
    font-weight: bold;
    color: #2B4F72;
    justify-content: center;
}

.textoFix2 {
    font-size: medium;
    font-weight: bold;
    text-align: center;
    margin-left: 5px;
    margin-right: 5px;
}

.textoFix3 {
    display: flex;
    flex: 1;
    font-size: larger;
    text-align: center;
    margin-left: 2px;
    margin-right: 2px;
    font-weight: bold;
    color: black;
    justify-content: center;
}

.textoFixGen {
    font-size: small;
    text-align: center;
    padding: 5px;
}

.textoFix11 {
    flex: 1;
    font-size: large;
    font-weight: bold;
    text-align: center;
    margin-left: 5px;
    margin-right: 5px;
    color: #449E5E;
}