.bodyEquipos {
	display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.bannerPie {
    flex: 1;
}

.BotonesDescarga {
    display: flex;
    flex: 1;
    flex-direction: column;
}

.botonJugadores {
    font-size:xx-small;
    background-color: #2B4F72;
    line-height: 0%;
    border-width: 0;
    color: white;
    justify-self: center;
    margin: 0%;
}

.botonesExtras {
    flex: 1;
    background-color: #2B4F72;
    color: white;
}

.DivBotones {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.divLista {
    align-self: center;
}

.Equipos {
    display: flex;
    flex-direction: column;
    width: 150px;
    height: 150px;
    background-color: rgba(255, 255, 255, 0.247);
    margin: 15px;
    padding: 15px;
}

.EscudoGrande {
    flex: 3;
    height: 15vh;
    margin: 0;
    align-self: center;
    justify-self: center;
}

.EquiposPeques {
    display: flex;
    flex-direction: column;
    width: 85px;
    height: 65px;
    background-color: rgba(255, 255, 255, 0.247);
    margin: 5px;
    padding: 5px;
}

.fotoEscudo {
    flex: 3;
    height: 8vh;
    margin: 0;
    align-self: center;
    justify-self: center;
}

.fotoEscudo1 {
    height: 8vh;
    align-self: center;
    justify-self: center;
}

.fotoEscudoPeque {
    flex: 3;
    height: 1vh;
    margin: 0;
    align-self: center;
    justify-self: center;
}

.ImagenDescarga {
    flex: 1;
    margin-top: 5px;
}

.ItemListaJugadores {
    display: flex;
    flex: 1;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    align-content: center;
}

.LogoHeader {
    display: flex;
    flex: 1.7;
}

.listaEquipos {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 120px;
    margin-bottom: 20px;
    margin-left: 30px;
    margin-right: 30px;
    justify-content: center;
    min-width: 288px;
    max-width: 900px;
}

.ListaJugadores {
    display: flex;
    flex-direction: column;
    flex: 2;
    align-content: center;
    margin-top: 10px;
}

.linea {
    background-color: #2B4F72;
    height: 2px;
}

.ListaNumero {
    color: #2B4F72;
    width: 100px;
    text-align: center;
    margin: 5px;
    font-weight: bold;
}

.ListaNombre {
    width: 400px;
    text-align: center;
    margin: 5px;
}

.ListaId {
    width: 200px;
    text-align: center;
    margin: 5px;
}

.listaEquiposPeque {
    display: flex;
    flex-direction: row;
    flex: 1;
    margin: 20;
    max-width: 700px;
    max-height: 70px;
    align-self: center;
    overflow: auto;
    -ms-overflow-style: none;
    background-color: #BAE5E4;
    padding: 10px;
    border-radius: 10px;
}

.muestraEquipo {
    flex: 8;
    margin-top: 120px;
    margin-bottom: 20px;
    margin-right: 50px;
    margin-left: 70px;
}

.nombreEquipo {
    flex: 0.5;
    margin-top: 10px;
    align-self: center;
    justify-self: center;
    text-align: center;
    font-weight: bold;
    color: #2B4F72;
}

.nombreEquipoPeque {
    flex: 0.5;
    align-self: center;
    justify-self: center;
    text-align: center;
    font-weight: bold;
    font-size: 6pt;
    color: #2B4F72;
}

.Subtitulo {
    font-size: 13pt;
    font-weight: bold;
    color: #2B4F72;
}

.Titulo {
    font-size: 20pt;
    font-weight: bold;
    text-align: center;
    margin-bottom: 10px;
}

.tituloTabla1 {
    margin-top: 18px;
    background-color: rgba(255, 255, 255, 0.548);
    justify-items: center;
}

.tituloTabla {
    margin: 0;
    padding: 0;
    font-size: 24px;
    font-weight: bold;
    align-self: center;
    text-align: center;
}

.tablaPosiciones {
    display: flex;
    justify-content: center;
    flex-direction: column;
    
}

.tablaPosiciones2 {
    display: flex;
    flex: 1;
    justify-content: center;
    flex-direction: column;
    
}

.tablaPosiciones1 {
    align-self: center;
    flex-direction: column;
}