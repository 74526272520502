.bannerPie {
    flex: 1;
}

.BotonesDescarga {
    display: flex;
    flex: 1;
    flex-direction: column;
}

.botonesExtras {
    flex: 1;
    background-color: #2B4F72;
    color: white;
    padding: 5px;
}

.celdas1 {
    padding-top: 20px;
    padding-bottom: 20px;
    background-color: rgba(245, 245, 245, 0.507);
}

.celdas2 {
    padding-top: 5px;
    padding-bottom: 5px;
    text-align: center; 
}

.DivBotones {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.filaUno {
    margin: 10px;
    
}

.fotoEscudo1 {
    display: flex;
    flex: 1;
    height: 4vh;
    margin: 4px;
}

.ImagenDescarga {
    flex: 1;
    margin-top: 5px;
}

.LogoHeader {
    display: flex;
    flex: 1.7;
}

.listaEquipos {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 120px;
    margin-bottom: 20px;
    margin-left: 30px;
    margin-right: 30px;
    justify-content: center;
    min-width: 288px;
    max-width: 900px;
}

.listaEquiposPeque {
    display: flex;
    flex-direction: row;
    flex: 1;
    margin: 20;
    max-width: 700px;
    max-height: 70px;
    align-self: center;
    overflow: auto;
    -ms-overflow-style: none;
    background-color: #BAE5E4;
    padding: 10px;
    border-radius: 10px;
}

.nombrescudo {
    flex-direction: row;
    display: flex;
    flex: 1;
}

.nombrequipo {
    display: flex;
    flex: 1;
    margin-left: 10px;
    font-weight: bold;
    align-items: center;
    color: #2B4F72
}

.tablaPosiciones1 {
    flex: 1;
    display: flex;
    justify-content: center;
    flex-direction: column;
} 

.tablaPosiciones {
    display: flex;
    flex: 1;
    justify-content: center;
    flex-direction: column;
}

.tituloTabla1 {
    margin-top: 18px;
    background-color: rgba(255, 255, 255, 0.548);
    justify-items: center;
}

.tituloTabla {
    margin: 0;
    padding: 0;
    font-size: 24px;
    font-weight: bold;
    align-self: center;
    text-align: center;
}

.tabla {
    flex: 1;
    padding-left: 50px;
    padding-right: 50px;
    margin: 5px;
    margin-top: 150px;
    margin-bottom: 10px;
}