.bannerLogo {
    flex: 1;
    width: 50%;
    height: 70%;
}

.Body {
    display: flex;
    width: 7vh;
    max-height: 100vh;
    flex: auto;
    flex-direction: column;
}

.Body1 {
    display: flex;
    width: 7vh;
    height: 80vh;
    flex: auto;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    
}

.botonInicio {
    margin: 10px;
    background-color: #2B4F72;
    color: white;
    border: none;
}

.botonRegresa {
    background-color: transparent;
    border: none;
    color: white;
}

.Contactos {
    background-color: black;
}

.DivTextoInicio {
    display: flex;
    flex-direction: column;
    background-color: azure;
}

.FotoInicio {
    background-color: aqua;
}

.General {
    width: 90%;
    height: 80vh;
    max-width: 1200px;
    max-height: 900px;
    display: flex;
    margin: auto;
    padding: 90px;
    flex-direction: row;
    flex: 1;
}

.Header {
    display: flex;
    width: 50vb;
    max-height: 100vh;
    flex: auto;
    justify-content: center;
    align-items: center;
}

.imagen {
    max-height: 90vh;
    max-width: 80vh;
    flex: 1;
}

.imagen1 {
    flex: 1;
    min-width: 40vh;
}

.imagen2 {
    flex: 1;
    min-height: 15vh;
    min-width: 40vh;
}
.imagen3 {
    flex: 0.2;
    min-height: 5vh;
    min-width: 40vh;
    color: white;
    text-align: center;
}

.inputInicio {
    margin: 10px;
    width: 230px;
    height: 30px;
    border-radius: 10px;
    padding: 5px;
}

.LogoHeader {
    display: flex;
    flex: 1.7;
}

.MenuHeader {
    flex: 1;
    display: flex;
    flex-direction: row;
    align-self: center;
    align-items: center;
    justify-content: center;
}

.subInicio {
    color: white;
    font-weight: bold;
}

.TextoInicio {
    background-color: blueviolet;
}